
import { get, post, put, Delete } from "@/api/axios";

// 获取banner列表
export const list = params => get('/web/partner/withdraw/list', params);

// 扣除押金
export const deductionMoneyHandle = params => put('/web/deposit/setting/deduct', params);

// 是否同意退还押金申请
export const isAgreestHandle = params => get('/web/deposit/setting/deduct/' + params.id, '');



// 销售合伙人类型设置-列表
export const typeList = (params) => get("/web/partner/type/list", params);

// 销售合伙人类型设置-优惠券-列表
export const couponList = (params) =>
    get("/web/partner/type/coupon/list", params);

// 销售合伙人类型设置-新增
export const typeAdd = (params) => post("/web/partner/type/add", params);

// 销售合伙人类型设置-删除
export const typeDelete = (params) =>
    Delete("/web/partner/type/delete/" + params.id, "");

// 销售合伙人类型设置-查看
export const typeView = (params) =>
    get("/web/partner/type/view/" + params.id, "");

// 销售合伙人类型设置-修改
export const typeUpdate = (params) => put("/web/partner/type/update", params);

// 销售合伙人资料审核-列表
export const examineList = (params) => get("/web/partner/examine/list", params);

// 销售合伙人资料审核-类型-列表
export const examineTypeList = (params) =>
    get("/web/partner/examine/type/list", params);

// 销售合伙人资料审核-类型-优惠券-列表
export const examineTypeCouponList = (params) =>
    get("/web/partner/examine/type/coupon/list", params);

// 销售合伙人资料审核-通过
export const examineAgree = (params) =>
    put("/web/partner/examine/agree", params);

// 销售合伙人资料审核-驳回
export const examineReject = (params) =>
    put("/web/partner/examine/reject", params);

// 绑定关系-列表
export const bindList = (params) => get("/web/partner/bind/list", params);

// 提现审核-列表
export const withdrawList = (params) =>
    get("/web/partner/withdraw/list", params);

// 提现审核-通过
export const withdrawAgree = (params) =>
    get("/web/partner/withdraw/agree/" + params.id, "");

// 提现审核-拒绝
export const withdrawRefuse = (params) =>
    put("/web/partner/withdraw/refuse", params);

// 收益明细-列表
export const detailList = (params) => get("/web/partner/detail/list", params);
